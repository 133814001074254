<template>
  <h1>Diagnostics</h1>
</template>

<script>
export default {
  name: 'Diagnostics'
}
</script>

<style scoped>
</style>
